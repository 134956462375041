<template>
  <div class="profile">
    <template v-if="$route.name === 'profile'">
      <p class="profile-header clearfix">
        <span class="profile-header-logo">
          <img :src="user.avatar" width="60" alt="">
        </span>
        <span class="nickname">{{user.nickname}}</span>
        <button class="text-primary logout-btn" @click="logoutConfirm">{{$tc('profile.itemLogout', 1)}}</button>
      </p>
      <div class="profile-main panel panel-default">
        <div class="panel-heading">
          {{$t('profile.title')}}
        </div>
        <div class="list-group">
          <router-link :to="{name: 'shelf'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/shelf.svg" alt="">
            {{$t('profile.itemShelf')}}
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'order-list'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/orders.svg" alt="">
            {{$t('profile.itemOrder')}}
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: user.isAgent ? 'agent-info' : 'agent'}" class="list-group-item clearfix">
            <i class="fa fa-child icon"></i>
            我是合夥人
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'profile-invitation'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/invitation.svg" alt="">
            {{$t('profile.itemInvitation')}}
            <span class="icon tag">Hot</span>
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'profile-coupons'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/coupons.svg" alt="">
            {{$t('profile.itemCoupons')}}
            <span class="icon tag">Now</span>
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'group-start'}" class="list-group-item clearfix" v-if="language !== 'en'">
            <img class="icon" style="width: 15px;" src="../../../static/images/profile/purchase.svg" alt="">
            {{$t('profile.itemGroup')}}
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
        </div>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import api from 'src/api/'
import { mapGetters, mapActions } from 'vuex'
import Cookies from 'js-cookie'
export default {
  name: '',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'user', 'language'
    ])
  },
  created () {},
  mounted () {
    this.fetchUserInfo()
  },
  beforeRouteLeave (to, from, next) {
    this.setLoading(false)
    next()
  },
  methods: {
    ...mapActions([
      'setLoading', 'setUser'
    ]),
    logoutConfirm () {
      this.$confirm({
        title: this.$t('profile.tipLogout'),
        okText: this.$t('button.cancel'),
        cancelText: this.$t('button.ok'),
        okClass: 'text-gray',
        cancelClass: 'text-red',
        onCancel: modal => {
          this.setUser({})
          Cookies.remove('m_user')
          window.location.href = '/'
          modal.close()
        }
      })
    },
    fetchUserInfo () {
      return api.fetchUser(this.user.uid).then(data => {
        if (data.errors) {
          return
        }
        this.setUser(data)
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  @import '../../styles/layout/Profile';
</style>
